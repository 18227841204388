import React, { useContext } from "react";
import Utils from 'utils/Utils';
import './Edition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';
import { SettingsContext } from 'Providers/SettingsProvider';

function EditionTile({edition}) {
  const { translate } = useContext(SettingsContext);
  const dataEdition=[
    {key_tr:edition.type ? translate(edition.type,'nom') : '',value:edition.title},
    Utils.renderArtistes(edition,false,false),
  ];
  return <div className="edition-bloc">
    <SlugLink item={edition}><CartoucheInfos data={dataEdition}/></SlugLink>
  </div>;
}
export default EditionTile;
