import { gql } from '@apollo/client';
const queries={
  eac_docs: gql`
  query EacDocs {
    eac_docs(filter:{status:{_eq: "Published" }}, limit:-1) {
      id
      sort
      title
      type {
        id
        titre
      }
      document {
        id
        title
        filesize
        type
      }
    }
    eac_docs_types {
      id
      titre
      tri
    }
  }
  `,
  expositions: gql`
  query Expositions {
    expositions(filter:{status:{_eq: "Published" }, type: { _eq: "centredart" }}, limit:-1) {
      id
      type
      title
      resume
      date_from
      date_to
      artistes {
        artistes_id(filter: { status : { _eq: "Published"}}) {
          id
          name
        }
      }
      animation {
        frames {
          id
          sort
          directus_files_id {
            id
          }
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      image {
        id
        credits
      }
      nocrop
      visuel_background
    }
  }
  `,
  expos: gql`
  query Expositions ($hier: String!) {
    expositions(filter:{status:{_eq: "Published" }, type: { _eq: "horslesmurs" }, date_to : { _gte: $hier} }, limit:-1) {
      id
      type
      title
      resume
      date_from
      date_to
      artistes {
        artistes_id(filter: { status : { _eq: "Published"}}) {
          id
          name
        }
      }
      animation {
        frames {
          id
          sort
          directus_files_id {
            id
          }
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      image {
        id
        credits
      }
      nocrop
      visuel_background
    }
  }
  `,
  residences: gql`
  query Residences {
    residences(filter:{status:{_eq: "Published" }}, limit:-1) {
      id
      title
      resume
      date_from
      date_to
      artistes {
        artistes_id {
          id
          name
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      image {
        id
        credits
      }
      nocrop
      visuel_background
    }
  }
  `,
  editions: gql`
  query Editions {
    editions_types(sort: ["nom"]) {
      id
      nom
    }
    editions(filter:{status:{_eq: "Published" }},sort: ["sort"], limit:-1) {
      id
      title
      sort
      artistes {
        artistes_id {
          id
          name
        }
      }
      type {
        id
        nom
        translations {
          languages_code {
            code
            name
          }
          nom
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      visuel {
        id
        credits
        width
        height
      }
      nocrop
      visuel_background
    }
  }
  `,
  ac: gql`
  query Ac($hier: String!) {
    ac {
      id
      title
      text
      text2
      text3
      plaquette {
        id
      }
      translations {
        languages_code {
          code
          name
        }
        title
        text
        text2
        text3
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    ac_project(filter:{status:{_eq: "Published" }}, limit:-1) {
      id
      title
      title_liste
      text
      construit
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    ac_instances(filter:{status:{_eq: "Published" }}, limit:-1) {
      id
      title
      text
      rest
      rest_titre
      rest_text
      rest_lieu
      rest_ville
      date_from
      date_to
      visuel {
        id
        credits
        width
        height
      }
      crop
      nocrop
      visuel_background
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      translations {
        languages_code {
          code
          name
        }
        title
        text
        rest_titre
        rest_text
      }
      ac_projects {
        ac_project_id(filter: { status : { _eq: "Published"}}) {
          id
          title
          text
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
        }
      }
      artistes {
        artistes_id(filter: { status : { _eq: "Published"}}) {
          id
          name
          bio
          translations {
            languages_code {
              code
              name
            }
            bio
          }
        }
      }
    }
    expositions (filter : { date_to : { _gte : $hier}, ac_instances: { ac_instances_id : { id: { _nnull:true}}}}) {
      id
      type
      title
      resume
      date_from
      date_to
      lieu
      ville
      translations {
        languages_code {
          code
          name
        }
        title
        resume
      }
      artistes {
        artistes_id(filter: { status : { _eq: "Published"}}) {
          id
          name
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    rdvs (filter: {
    date : { _gte : $hier},
    _and:[
      {status : { _eq: "Published"}},
      {_or:[
        {agenda:{ _eq: "eac"}},
        {agenda:{ _eq: "both"}}
      ]}
    ]}, sort: ["date"], limit:-1) {
      id
      types {
        types_id {
          id
          name
          translations {
            languages_code {
              code
              name
            }
            name
          }
        }
      }
      title
      date
      time_from
      time_to
      lieu
      ville
      visuel{
        id
        credits
        width
        height
      }
      crop
      resume
      repetitions {
        titre
        date
        time_from
        time_to
      }
      slugs {
          slug
          date_created
      }
    }
  }
  `,
  ac_project: gql`
  query AcProject($slug: String!) {
    ac {
      id
      title
      text
      text2
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    propositions: ac_project(filter:{status:{_eq: "Published" }}, limit:-1) {
      id
      title
      construit
      translations {
        languages_code {
          code
          name
        }
        title
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    ac_project(filter: { slugs: {slug: {_eq : $slug}}, status : { _eq: "Published"}}, limit:-1) {
      id
      title
      text
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      ac_instances {
        ac_instances_id(filter: { status : { _eq: "Published"}}) {
          id
          title
          text
          ac_projects {
            ac_project_id {
              title
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
            }
          }
          visuel {
            id
            credits
            width
            height
          }
          crop
          nocrop
          visuel_background
          slugs(sort: ["-date_created"], limit: 1) {
            slug
          }
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
  }
  `,
  ac_instance: gql`
  query AcInstance($slug: String!) {
    ac_instances(filter: { slugs: {slug: {_eq : $slug}}, status : { _eq: "Published"}}, limit:-1) {
      id
      title
      text
      rest
      rest_titre
      rest_text
      rest_lieu
      rest_ville
      date_from
      date_to
      visuel {
        id
        credits
        width
        height
      }
      crop
      expositions {
        expositions_id(filter: { status : { _eq: "Published"}}) {
          id
          type
          title
          resume
          date_from
          date_to
          lieu
          ville
          translations {
            languages_code {
              code
              name
            }
            title
            resume
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs(sort: ["-date_created"], limit: 1) {
            slug
          }
        }
      }
      diaporama {
        id
        directus_files_id {
          id
          title
          filesize
          type
          credits
        }
      }
      nocrop
      visuel_background
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      translations {
        languages_code {
          code
          name
        }
        title
        text
        rest_titre
        rest_text
      }
      ac_projects {
        ac_project_id(filter: { status : { _eq: "Published"}}) {
          id
          title
          text
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
        }
      }
      artistes {
        artistes_id(filter: { status : { _eq: "Published"}}) {
          id
          name
          bio
          translations {
            languages_code {
              code
              name
            }
            bio
          }
        }
      }
      partners {
        partner_id(filter: { status : { _eq: "Published"}}) {
          id
          name
          type
          logo {
            id
            credits
          }
        }
      }
      documents {
        id
        directus_files_id {
          id
          title
          filesize
          type
        }
      }
      rdvs (filter: { rdvs_id: { id: {_nnull:true}}}){
        rdvs_id(filter: { status : { _eq: "Published"}}){
          id
          types (filter: { types_id: { id: {_nnull:true}}}) {
            types_id {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
            credits
            width
            height
          }
          crop
          resume
          artistes (filter: { artistes_id: { id: {_nnull:true}}}) {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
              slug
              date_created
          }
        }
      }
    }
  }
  `,
  exposition:gql`
    query Exposition($slug: String!){
        expositions(filter: {slugs: {slug: {_eq : $slug}}, status: { _eq:"Published"}, type: { _eq: "centredart" } }, limit:-1) {
          id
          type
          title
          resume
          text
          date_from
          date_to
          documents {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
              credits
            }
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          partners {
            partner_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              type
              logo {
                id
                credits
              }
            }
          }
          animation {
            frames {
          	  id
              sort
              directus_files_id {
                id
              }
          	}
          }
          image {
            id
            credits
          }
          nocrop
          visuel_background
          slugs {
              slug
              date_created
          }
          translations {
            languages_code {
              code
              name
            }
            title
            resume
            text
          }
          rdvs{
            rdvs_id(filter: { status : { _eq: "Published"}}) {
              id
              types {
                types_id {
                  id
                  name
                  translations {
                    languages_code {
                      code
                      name
                    }
                    name
                  }
                }
              }
              agenda
              title
              date
              time_from
              time_to
              lieu
              ville
              visuel{
                id
                credits
                width
                height
              }
              crop
              resume
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              repetitions {
                titre
                date
                time_from
                time_to
              }
              slugs {
                  slug
                  date_created
              }
            }
          }
        }
    }
  `,
  expo:gql`
    query Exposition($slug: String!){
        expositions(filter: {slugs: {slug: {_eq : $slug}}, status: { _eq:"Published"}, type: { _eq: "horslesmurs" } }, limit:-1) {
          id
          type
          title
          resume
          text
          date_from
          date_to
          documents {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
              credits
            }
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          partners {
            partner_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              type
              logo {
                id
                credits
              }
            }
          }
          animation {
            frames {
          	  id
              sort
              directus_files_id {
                id
              }
          	}
          }
          image {
            id
            credits
          }
          nocrop
          visuel_background
          slugs {
              slug
              date_created
          }
          translations {
            languages_code {
              code
              name
            }
            title
            resume
            text
          }
          ac_instances {
            ac_instances_id(filter: { status : { _eq: "Published"}}) {
              id
              title
              text
              visuel {
                id
                credits
                width
                height
              }
              crop
              nocrop
              visuel_background
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
              translations {
                languages_code {
                  code
                  name
                }
                title
                text
              }
              ac_projects {
                ac_project_id(filter: { status : { _eq: "Published"}}) {
                  id
                  title
                  translations {
                    languages_code {
                      code
                      name
                    }
                    title
                  }
                }
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                  bio
                  translations {
                    languages_code {
                      code
                      name
                    }
                    bio
                  }
                }
              }
            }
          }
          residences {
            residences_id(filter: { status : { _eq: "Published"}}) {
              id
              title
              date_from
              date_to
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
            }
          }
          rdvs{
            rdvs_id(filter: { status : { _eq: "Published"}}) {
              id
              types {
                types_id {
                  id
                  name
                  translations {
                    languages_code {
                      code
                      name
                    }
                    name
                  }
                }
              }
              agenda
              title
              date
              time_from
              time_to
              lieu
              ville
              visuel{
                id
                credits
                width
                height
              }
              crop
              resume
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              repetitions {
                titre
                date
                time_from
                time_to
              }
              slugs {
                  slug
                  date_created
              }
            }
          }
        }
    }
  `,
  residence:gql`
    query Residence($slug: String!){
        residences(filter: { slugs: {slug: {_eq : $slug}}, status : { _eq: "Published"}}, limit:-1) {
          id
          title
          resume
          text
          date_from
          date_to
          ressources {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
              credits
            }
          }
          partners {
            partner_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              type
              logo {
                id
                credits
              }
            }
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          image {
            id
            credits
          }
          slugs {
              slug
              date_created
          }
          translations {
            languages_code {
              code
              name
            }
            title
            resume
            text
          }
          expositions {
            expositions_id(filter: { status : { _eq: "Published"}}) {
              id
              type
              title
              resume
              date_from
              date_to
              lieu
              ville
              translations {
                languages_code {
                  code
                  name
                }
                title
                resume
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
            }
          }
          rdvs{
            rdvs_id(filter: { status : { _eq: "Published"}}) {
              id
              agenda
              types {
                types_id {
                  id
                  name
                  translations {
                    languages_code {
                      code
                      name
                    }
                    name
                  }
                }
              }
              title
              date
              time_from
              time_to
              lieu
              ville
              visuel{
                id
                credits
                width
                height
              }
              crop
              resume
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              repetitions {
                titre
                date
                time_from
                time_to
              }
              slugs {
                  slug
                  date_created
              }
            }
          }
        }
    }
  `,
  edition:gql`
    query Edition($slug: String!){
        editions(filter: { slugs: {slug: {_eq : $slug}}, status : { _eq: "Published"}}, limit:-1) {
          id
          title
          text
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
              credits
            }
          }
          partners {
            partner_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              type
              logo {
                id
                credits
              }
            }
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          visuel {
            id
            credits
            width
            height
          }
          slugs {
              slug
              date_created
          }
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
          rdvs{
            rdvs_id(filter: { status : { _eq: "Published"}}) {
              id
              types {
                types_id {
                  id
                  name
                  translations {
                    languages_code {
                      code
                      name
                    }
                    name
                  }
                }
              }
              agenda
              title
              date
              time_from
              time_to
              lieu
              ville
              visuel{
                id
                credits
                width
                height
              }
              crop
              resume
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              repetitions {
                titre
                date
                time_from
                time_to
              }
              slugs {
                  slug
                  date_created
              }
            }
          }
        }
    }
  `,
  langswitch:gql`
    query LangSwitch{
        languages {
          code
        }
    }
  `,
  page:gql`
    query Page($slug: String!){
        pages(filter: {slugs: {slug: {_eq : $slug}}, status: { _eq:"Published"} }, limit:-1) {
          id
          title
          text
          rubrique {
            id
            title
            translations {
              languages_code {
                code
                name
              }
              title
            }
          }
          visuel{
            id
            credits
            width
            height
          }
          slugs {
              slug
              date_created
          }
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
              credits
            }
          }
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
        }
    }
  `,
  accueil:gql`
    query Accueil($maintenant: String!,$hier: String!){
        une {
          blocs {
            id
            type
            exposition {
              id
              title
              resume
              translations {
                languages_code {
                  code
                  name
                }
                title
                resume
              }
              date_from
              date_to
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
            }
            residence {
              id
              title
              resume
              date_from
              date_to
              artistes {
                artistes_id {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
            }
            edition {
              id
              title
              type {
                nom
                translations {
                  languages_code {
                    code
                    name
                  }
                  nom
                }
              }
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs {
                  slug
                  date_created
              }
            }
            rdv {
              id
              agenda
              types {
                types_id {
                  id
                  name
                  translations {
                    languages_code {
                      code
                      name
                    }
                    name
                  }
                }
              }
              title
              date
              time_from
              time_to
              lieu
              ville
              visuel{
                id
                credits
                width
                height
              }
              nocrop
              visuel_background
              resume
              repetitions {
                titre
                date
                time_from
                time_to
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs {
                  slug
                  date_created
              }
            }
            ac_instance {
              id
              title
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              visuel{
                id
                credits
                width
                height
              }
              nocrop
              visuel_background
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
              ac_projects {
                ac_project_id(filter: { status : { _eq: "Published"}}) {
                  id
                  title
                  translations {
                    languages_code {
                      code
                      name
                    }
                    title
                  }
                }
              }
            }
            auto
            size
            rdvactu
          }
        }
        rdvs(sort: ["date"], filter: { status : { _eq: "Published"}, agenda: { _neq:"blog"}, date: { _gte: $hier}}, limit:10) {
          id
          agenda
          types {
            types_id {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
            }
          }
          visuel_background
          nocrop
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
            credits
            width
            height
          }
          crop
          resume
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
            slug
            date_created
          }
        }
        actus: rdvs(sort: ["-date"], filter: { status : { _eq: "Published"}, agenda: { _eq:"blog"}}, limit:10) {
          id
          agenda
          types {
            types_id {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
            }
          }
          visuel_background
          nocrop
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
            credits
            width
            height
          }
          crop
          resume
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
            slug
            date_created
          }
        }
        expositions(filter:{ status : { _eq: "Published"}, type: { _eq: "centredart"}, _and: [{date_from: { _lte: $maintenant}},{date_to: { _gte: $hier}}]}, limit:-1) {
          id
          type
          title
          resume
          translations {
            languages_code {
              code
              name
            }
            title
            resume
          }
          date_from
          date_to
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          animation {
          	frames {
          	  id
              sort
              directus_files_id {
                id
              }
          	}
          }
          slugs(sort: ["-date_created"], limit: 1) {
            slug
          }
          image {
            id
            credits
          }
          nocrop
          visuel_background
        }
    }
  `,
  rdvs:gql`
    query Rdvs{
        types(sort: ["name"]) {
          id
          name
        }
        rdvs(filter: { status : { _eq: "Published"}, agenda: {_neq:"blog"}}, sort:["-date"], limit:-1) {
          id
          types {
            types_id {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
            credits
            width
            height
          }
          crop
          nocrop
          visuel_background
          resume
          repetitions {
            titre
            date
            time_from
            time_to
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs {
              slug
              date_created
          }
        }
    }
  `,
  rdv:gql`
    query Rdv($slug: String!){
        rdvs(filter: {slugs: {slug: {_eq : $slug}}, status : { _eq: "Published"}, agenda: {_neq:"blog"}}, limit:-1) {
          id
          types {
            types_id {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
            credits
            width
            height
          }
          crop
          nocrop
          visuel_background
          resume
          text
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
              slug
              date_created
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          partners {
            partner_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              type
              logo {
                id
                credits
              }
            }
          }
          documents {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          expositions {
            expositions_id(filter: { status : { _eq: "Published"}}) {
              id
              type
              title
              date_from
              date_to
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
            }
          }
          residences {
            residences_id(filter: { status : { _eq: "Published"}}) {
              id
              title
              date_from
              date_to
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
            }
          }
          ac_instances {
            ac_instances_id(filter: { status : { _eq: "Published"}}) {
              id
              title
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
              ac_projects {
                ac_project_id(filter: { status : { _eq: "Published"}}) {
                  id
                  title
                  translations {
                    languages_code {
                      code
                      name
                    }
                    title
                  }
                }
              }
            }
          }
        }
    }
  `,
  blogs:gql`
    query Blogs{
        types(sort: ["name"]) {
          id
          name
        }
        blogs: rdvs(filter: { status : { _eq: "Published"}, agenda: {_eq:"blog"}}, sort:["-date"], limit:-1) {
          id
          agenda
          types {
            types_id {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
            credits
            width
            height
          }
          crop
          nocrop
          visuel_background
          resume
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
              slug
              date_created
          }
        }
    }
  `,
  blog:gql`
    query Blog($slug: String!){
        blogs: rdvs(filter: {slugs: {slug: {_eq : $slug}}, status : { _eq: "Published"}, agenda: {_eq:"blog"}}, limit:-1) {
          id
          types {
            types_id {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
            credits
            width
            height
          }
          crop
          visuel_background
          resume
          text
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
              slug
              date_created
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          partners {
            partner_id(filter: { status : { _eq: "Published"}}) {
              id
              name
              type
              logo {
                id
                credits
              }
            }
          }
          documents {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          expositions {
            expositions_id(filter: { status : { _eq: "Published"}}) {
              id
              type
              title
              date_from
              date_to
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
            }
          }
          residences {
            residences_id(filter: { status : { _eq: "Published"}}) {
              id
              title
              date_from
              date_to
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
            }
          }
          ac_instances {
            ac_instances_id(filter: { status : { _eq: "Published"}}) {
              id
              title
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
              artistes {
                artistes_id(filter: { status : { _eq: "Published"}}) {
                  id
                  name
                }
              }
              slugs(sort: ["-date_created"], limit: 1) {
                slug
              }
              ac_projects {
                ac_project_id(filter: { status : { _eq: "Published"}}) {
                  id
                  title
                  translations {
                    languages_code {
                      code
                      name
                    }
                    title
                  }
                }
              }
            }
          }
        }
    }
  `,
  search:`
    query Search {
        expositions(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          type
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs {
            slug
            date_created
          }
          __typename
        }
        horslesmurs: expositions(filter: { status : { _eq: "Published"}, type: { _eq: "horslesmurs" }}, limit:-1) {
          id
          type
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs {
            slug
            date_created
          }
          __typename
        }
        pages(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          slugs {
            slug
            date_created
          }
          __typename
        }
        rdvs(filter: { status : { _eq: "Published"}, agenda: {_neq:"blog"}}, sort:["date"], limit:-1) {
          id
          title
          agenda
          types {
            types_id {
              id
              name
              translations {
                languages_code {
                  code
                  name
                }
                name
              }
            }
          }
          translations {
            languages_code {
              code
              name
            }
            title
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs {
              slug
              date_created
          }
          __typename
        }
        blogs : rdvs(filter: { status : { _eq: "Published"}, agenda: {_eq:"blog"}}, limit:-1) {
          id
          title
          agenda
          translations {
            languages_code {
              code
              name
            }
            title
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs {
              slug
              date_created
          }
          __typename
        }
        residences(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs {
              slug
              date_created
          }
          __typename
        }
        editions(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs {
              slug
              date_created
          }
          __typename
        }
        ac_project(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          slugs {
              slug
              date_created
          }
          __typename
        }
        ac_instances(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          artistes {
            artistes_id(filter: { status : { _eq: "Published"}}) {
              id
              name
            }
          }
          slugs {
              slug
              date_created
          }
          __typename
        }
        eac_docs(filter:{status:{_eq: "Published" }}, limit:-1) {
          id
          title
          document {
            id
          }
          type {
            titre
          }
        }
    }
  `,
  rubriques:gql`
    query Rubriques($hier: String!) {
        expositions (filter: { status : { _eq: "Published"}, type: { _eq: "horslesmurs"}, date_to: { _gte:$hier}}, limit:-1) {
          id
        }
        rubriques(filter: { status : { _eq: "Published"}}, limit:-1) {
          id
          title
          typr
          slugs {
              slug
          }
          translations {
              languages_code {
                code
                name
              }
              title
          }
          pages(filter: { status : { _eq: "Published"}}, limit:-1) {
            id
            title
            translations {
              languages_code {
                code
                name
              }
              title
            }
            slugs {
              slug
            }
          }
        }
        pages(filter: { status : { _eq: "Published"}, rubrique: {id:{_null:true}}}, limit:-1) {
          id
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          slugs {
            slug
          }
        }
    }
  `,
  rubrique:gql`
    query Rubrique($slug: String!) {
        rubriques(filter: {slugs: {slug: {_eq : $slug}}, status: { _eq:"Published"} }, limit:-1) {
          id
          title
          typr
          slugs {
              slug
          }
          translations {
              languages_code {
                code
                name
              }
              title
          }
          pages(filter: { status : { _eq: "Published"}}, limit:-1) {
            id
            title
            text
            rubrique {
              id
              title
              translations {
                languages_code {
                  code
                  name
                }
                title
              }
            }
            visuel{
              id
              credits
              width
              height
            }
            slugs {
                slug
                date_created
            }
            diaporama {
              id
              directus_files_id {
                id
                title
                filesize
                type
                credits
              }
            }
            translations {
              languages_code {
                code
                name
              }
              title
              text
            }
          }
        }
    }
  `,
  settings:gql`
    query Settings{
        settings {
          contrast_color
          sitename
          websitename
          footer1
          footer2
          footer3
          footer4
          plateforme_baseline
          plateforme_desc_1
          plateforme_desc_2
          plateforme_desc_3
          translations {
            languages_code {
              code
              name
            }
            footer1
            footer2
            footer3
            footer4
            plateforme_baseline
            plateforme_desc_1
            plateforme_desc_2
            plateforme_desc_3
          }
        }
        interface(limit:-1) {
          key
          value
          text
          translations {
            languages_code {
              code
              name
            }
            value
            text
          }
        }
    }
  `,
}
export default queries;
