import React, {useState,useEffect} from "react";
import './Share.scss';
import {ReactComponent as ShareSvg} from 'imgs/Share.svg';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

function Share() {
  const [ show,setShow ] = useState(false);
  const [ share,setShare ] = useState({
    title: document.title,
    text: document.title,
    url: window.location.href,
  });
  const handleShare=(e)=>{
    e.stopPropagation();
    const share={
      title: document.title,
      text: document.title,
      url: window.location.href,
    };
    setShare(share);
    if (window.navigator.share) {
      navigator.share(share);
    } else {
      setShow(state=>!state);
    }
  }
  useEffect(()=>{
    const handleClick=()=>setShow(false);
    document.addEventListener('click',handleClick);
    return ()=>{
      document.removeEventListener('click',handleClick);
    }
  },[setShow]);
  return <div className="share-container">
    <div className="share" onClick={handleShare}>
      <ShareSvg/>
    </div>
    <div className={"share-popup"+(show ?' show':'')} onClick={(e)=>e.stopPropagation()}>
      <div className="share-inner">
        <div className="share-item">
          <FacebookShareButton url={share.url}>
            <FacebookIcon round size={40}/>
          </FacebookShareButton>
        </div>
        <div className="share-item">
          <TwitterShareButton url={share.url}>
            <TwitterIcon round size={40}/>
          </TwitterShareButton>
        </div>
    </div>
    </div>
  </div>;
}

export default Share;
