import React from "react";
import './Image.scss';
import Conf from 'utils/Conf';

function Image({file,thumbnailKey,ratio=1,background='white',nocrop=false,align=null,credits=false,crop="{}"}) {
  const {x,y,size} = JSON.parse(crop);
  let src;
  if (!isNaN(x) && !isNaN(y) && !isNaN(size)) {
    const params=encodeURIComponent(JSON.stringify([['extract',{ left: parseInt(x), top: parseInt(y), width: parseInt(size), height: parseInt(size) }]]));
    src=`${Conf.apiUrl}/assets/${file.id}?transforms=${params}`;
  } else {
    src=`${Conf.apiUrl}/assets/${file.id}?key=${thumbnailKey}`;
  }
  const {width,height}=file;
  let imgRatio;
  if (width>0 && height>0) imgRatio=height/width;
  const actualRatio=thumbnailKey.endsWith('-nocrop') && imgRatio ? imgRatio : ratio;
  return <><div className={"image-container"+(nocrop ? " nocrop" : '')} style={{paddingBottom:(actualRatio*100)+'%'}}>
      {Boolean(file) ? <>
        <img alt="" src={src} crossOrigin="anonymous" style={{objectPosition:align}}/>
      </> : <div className="image-placeholder"/>}
  </div>
  {credits && file.credits && <div className="image-credits">© {file.credits}</div>}
  </>;
}

export default Image;
