import React, { useEffect, useContext } from "react";
import './Accueil.scss'
import ExpoEnCours from 'components/Exposition/ExpoEnCours';
import RdvTile from 'components/Rdv/RdvTile';
import BlogTile from 'components/Blog/BlogTile';
import AcInstanceTile from 'components/Ac/AcInstanceTile';
import ResidenceTile from 'components/Residence/ResidenceTile';
import ExpositionTile from 'components/Exposition/ExpositionTile';
import EditionTile from 'components/Edition/EditionTile';
import Grid from '@material-ui/core/Grid';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import { DateTime } from "luxon";
import { SettingsContext } from 'Providers/SettingsProvider';
import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import Mod from 'components/Mod';
SwiperCore.use([Navigation, Autoplay, A11y]);
function Accueil() {
  const { updateLoadState, fetchedList, settings } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.accueil,{
    variables: {
      maintenant:DateTime.now().startOf('day').toISODate(),
      hier:DateTime.now().minus({ days: 1 }).startOf('day').toISODate(),
    },
  });
  const uuid='accueil';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let une= data ? data.une : {blocs:[]};
  let rdvs= data ? data.rdvs : [];
  let actus= data ? data.actus : [];
  let expos= data ? data.expositions.filter((o)=>DateTime.fromISO(o.date_to)>=DateTime.now().startOf('day')) : [];
  let actuIdx=0;
  let rdvIdx=0;
  useEffect(()=>{
    if (settings && settings.websitename) document.title=settings.websitename;
  },[settings]);
  return (<div className="accueil">
    <Swiper
    speed={800}
    loop
    autoplay={{
      delay: 6000,
    }}
    enabled={expos.length>1}
    navigation={expos.length>1}
    >
      {expos.map((expo,i)=><SwiperSlide key={expo.id}>
        <ExpoEnCours expo={expo} home={true}/>
      </SwiperSlide>)}
    </Swiper>
    <Grid container spacing={2}>
      {une && une.blocs.map((bloc)=>{
        if (bloc.type==='rdv') {
          let rdv;
          if (bloc.auto==='auto') {
            if (bloc.rdvactu==='rdv') {
              rdv=rdvs[rdvIdx];
              rdvIdx++;
            }
            if (bloc.rdvactu==='actualite') {
              rdv=actus[actuIdx];
              actuIdx++;
            }
          } else {
            rdv=bloc.rdv;
          }
          if (bloc.size==='billet' && rdv) {
            return <Grid key={bloc.id} item xs={12} sm={6} md={4} >
              {rdv ? (rdv.agenda==='blog' ? <BlogTile blog={rdv}/> : <RdvTile rdv={rdv}/>) : null}
            </Grid>
          }
          if (bloc.size==='large' && rdv) {
            return <Grid key={bloc.id} item xs={12}>
              {rdv ? (rdv.agenda==='blog' ? <BlogTile blog={rdv}/> : <RdvTile rdv={rdv}/>) : null}
            </Grid>
          }
        }
        if (bloc.type==='ac_instance' && bloc.ac_instance) {
          if (bloc.size==='billet') {
            return <Grid key={bloc.id} item xs={12} sm={6} md={4} >
              <AcInstanceTile acInstance={bloc.ac_instance}/>
            </Grid>
          }
          if (bloc.size==='large') {
            return <Grid key={bloc.id} item xs={12}>
              EAC
            </Grid>
          }
        }
        if (bloc.type==='exposition' && bloc.exposition) {
          return <Grid key={bloc.id} item xs={12}>
            <ExpositionTile expo={bloc.exposition}/>
          </Grid>
        }
        if (bloc.type==='residence' && bloc.residence) {
          return <Grid key={bloc.id} item xs={12}>
            <ResidenceTile residence={bloc.residence}/>
          </Grid>
        }
        if (bloc.type==='edition' && bloc.edition) {
          return <Grid key={bloc.id} item xs={12}>
            <EditionTile edition={bloc.edition}/>
          </Grid>
        }
        return null;
      })}
    </Grid>
    <Mod url={'une'} refresh={refetch}/>
  </div>);
}
export default Accueil;
