import React, {useContext, useEffect} from "react";
import { useQuery } from '@apollo/client';
import './Ac.scss'
import Queries from 'utils/Queries';
import Grid from '@material-ui/core/Grid';
import CartoucheInfos from 'components/CartoucheInfos';
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Router/SlugLink';
import Conf from 'utils/Conf';
import { DateTime } from "luxon";
import Utils from 'utils/Utils';
import Mod from 'components/Mod';
import {
  Link
} from "react-router-dom";
import RdvTile from 'components/Rdv/RdvTile';
import ExpoMini from 'components/Exposition/ExpoMini';

function Ac() {
  const { translate, t, updateLoadState, fetchedList } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.ac,{
    variables: {
      hier:DateTime.now().minus({ days: 1 }).toISODate(),
    },
  });
  const uuid='ac';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const ac=data ? data.ac : null;
  const acProjects=data ? data.ac_project : [];
  const rdvs=data ? data.rdvs : [];
  const dataAc=ac ? [
    {key:null,value:translate(ac,'title')},
  ]: [];
  const dataRessources=[
    {key:null,value:t('ressources-main')},
  ];
  const restitutions=data ? data.expositions : [];
  useEffect(()=>{
    if (ac && ac.title) document.title=ac.title
  },[ac]);
  return ac ? <div className="ac">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <div className="spacer5"></div>
        <CartoucheInfos data={dataAc}/>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <div className="ac-text select">
              {Utils.parseWithLinks(ac ? translate(ac,'text') : '')}
            </div>
            {acProjects && <div className="liste">
              {acProjects.filter((o)=>o.construit==='non').map((acProject)=><div className="list-item" key={acProject.id}>
                - <SlugLink key={acProject.id} item={acProject}>{translate(acProject,'title')}</SlugLink>
              </div>)}
            </div>}
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className="ac-text select">
              {Utils.parseWithLinks(ac ? translate(ac,'text3') : '')}
            </div>
            {acProjects && <div className="liste">
              {acProjects.filter((o)=>o.construit==='oui').map((acProject)=><div className="list-item" key={acProject.id}>
                - <SlugLink key={acProject.id} item={acProject}>{translate(acProject,'title')}</SlugLink>
              </div>)}
            </div>}
          </Grid>
          <Grid item xs={12}>
            <div className="ac-text select">
              {Utils.parseWithLinks(ac ? translate(ac,'text2') : '')}
            </div>
          </Grid>
        </Grid>
        <hr />
        <div className="spacer5"></div>
        <CartoucheInfos data={dataRessources}/>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Link to="/ressources">
              <div className="ac-ressources">
                {t('ac-ressources')}
              </div>
            </Link>
          </Grid>
          {ac.plaquette && <Grid item xs={12} lg={6}>
            <a href={Conf.apiUrl+'/assets/'+ac.plaquette.id+'?download'} target="_blank" rel="noreferrer">
              <div className="ac-ressources">
                {t('plaquette')}
              </div>
            </a>
          </Grid>}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className="ac-sidebar">
          <div className="spacer5"></div>
          {restitutions.length>0 && <div className="type-billet">{t('restitutions')}</div>}
          {restitutions.map((restitution)=><ExpoMini key={restitution.id} expo={restitution} mini={true}/>)}
          <div className="spacer"></div>
          {rdvs.length>0 && <div className="type-billet">{t('rendez-vous')}</div>}
          {rdvs.map((rdv,i)=><RdvTile key={i} rdv={rdv} size="mini"/>)}
        </div>
      </Grid>
    </Grid>
    <Mod url={'ac'} refresh={refetch}/>
  </div> : '';
}
export default Ac;
