import React, { useContext } from "react";
import './Footer.scss';
import Grid from '@material-ui/core/Grid';
import Utils from 'utils/Utils';
import { SettingsContext } from 'Providers/SettingsProvider';

import {
  FacebookIcon,
  TwitterIcon,
} from "react-share";

function FooterInner() {
  const { translate, settings } = useContext(SettingsContext);
  return <div className="footer-inner">
    <Grid container spacing={1}>
      <Grid item xs={6} md={3} className="footer-inner-cartouche">
        {Utils.parseWithLinks(translate(settings,'footer1'))}
      </Grid>
      <Grid item xs={6} md={3} className="footer-inner-cartouche">
        {Utils.parseWithLinks(translate(settings,'footer2'))}
      </Grid>
      <Grid item xs={6} md={3} className="footer-inner-cartouche">
        {Utils.parseWithLinks(translate(settings,'footer3'))}
      </Grid>
      <Grid item xs={6} md={3} className="rs">
        <a href="https://www.facebook.com/centredartlelait" target="_blank" rel="noreferrer"><FacebookIcon round size={30}/></a> &nbsp;
        <a href="https://twitter.com/MurielleEdet" target="_blank" rel="noreferrer"><TwitterIcon round size={30}/></a>
      </Grid>
      <Grid item xs={12}>
        {Utils.parseWithLinks(translate(settings,'footer4'))}
      </Grid>
    </Grid>
  </div>;
}

export default FooterInner;
