import React, { useEffect, useContext } from "react";
import './Expositions.scss'
import ExpoEnCours from 'components/Exposition/ExpoEnCours';
import ExpoPassee from 'components/Exposition/ExpoPassee';
import ExpoAvenir from 'components/Exposition/ExpoAvenir';
import Pagination from 'components/Pagination';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import { DateTime } from "luxon";
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';
import Mod from 'components/Mod';

function Expositions() {
  const { updateLoadState, fetchedList, appState, t } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.expositions);
  const uuid='expositions';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let exposEnCours= data ? data.expositions.map((o)=>({...o,typeExpo:'enCours'})).filter((o)=>DateTime.fromISO(o.date_to)>=DateTime.now().startOf('day') && DateTime.fromISO(o.date_from)<=DateTime.now().startOf('day')).sort((a,b)=>DateTime.fromISO(a.date_from)-DateTime.fromISO(b.date_from)) : [];
  let exposPassees= data ? data.expositions.map((o)=>({...o,typeExpo:'passees'})).filter((o)=>DateTime.fromISO(o.date_to)<DateTime.now().startOf('day')).sort((a,b)=>DateTime.fromISO(b.date_from)-DateTime.fromISO(a.date_from)) : [];
  let exposAvenir= data ? data.expositions.map((o)=>({...o,typeExpo:'aVenir'})).filter((o)=>DateTime.fromISO(o.date_from)>DateTime.now().startOf('day')).sort((a,b)=>DateTime.fromISO(a.date_from)-DateTime.fromISO(b.date_from)) : [];
  const nb=exposPassees ? exposPassees.length : 0;
  const page = (appState.paginations[uuid] && appState.paginations[uuid].page) || 1;
  useEffect(()=>{
    document.title=t('expositions')
  },[t]);
  return (<div className="expositions">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {exposEnCours.map((expo,i)=><ExpoEnCours key={expo.id} expo={expo}/>)}
        {nb>0 && <Pagination paginationId={uuid} page={page} nb={nb} typeSingulier='exposition-passee' typePluriel='expositions-passees' perPage={Conf.perPage}/>}
        {exposPassees.map((expo,i)=>{
          const from=(page-1)*Conf.perPage+1;
          const to=Math.min(nb,page*Conf.perPage);
          if (i+1>=from && i+1<=to) {
            return <ExpoPassee key={expo.id} expo={expo}/>;
          }
          return null;
        })}
      </Grid>
      <Grid item xs={12} md={4}>
        <div className="expositions-a-venir">
          {exposAvenir.map((expo,i)=><ExpoAvenir key={expo.id} expo={expo}/>)}
        </div>
      </Grid>
    </Grid>
    <Mod url={'expositions'} refresh={refetch}/>
  </div>);
}
export default Expositions;
