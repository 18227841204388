import React from "react";
import Utils from 'utils/Utils';
import './Residence.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';
import { DateTime } from "luxon";

function ResidenceTile({residence}) {
  let surtitre='';
  if(DateTime.fromISO(residence.date_to)>=DateTime.now() && DateTime.fromISO(residence.date_from)<=DateTime.now()) surtitre='residence-en-cours';
  if(DateTime.fromISO(residence.date_to)<DateTime.now()) surtitre='residence-passee';
  if(DateTime.fromISO(residence.date_from)>DateTime.now()) surtitre='residence-a-venir';
  const dataResidence=[
    {key:surtitre,value:residence.title},
    Utils.renderArtistes(residence,false,false),
    {key:null,value:Utils.dates(residence)},
  ];
  return <div className="residence-bloc">
    <SlugLink item={residence}><CartoucheInfos data={dataResidence}/></SlugLink>
  </div>;
}
export default ResidenceTile;
