import React, { useEffect, useContext } from "react";
import './Expositions.scss'
import ExpoEnCours from 'components/Exposition/ExpoEnCours';
import ExpoAvenir from 'components/Exposition/ExpoAvenir';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import { DateTime } from "luxon";
import { SettingsContext } from 'Providers/SettingsProvider';
import Mod from 'components/Mod';

function Expos() {
  const { updateLoadState, fetchedList, t } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.expos,{
    variables: {
      hier:DateTime.now().minus({ days: 1 }).startOf('day').toISODate(),
    },
  });
  const uuid='expos';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let exposEnCours= data ? data.expositions.map((o)=>({...o,typeExpo:'enCours'})).filter((o)=>DateTime.fromISO(o.date_to)>=DateTime.now().startOf('day') && DateTime.fromISO(o.date_from)<=DateTime.now().startOf('day')).sort((a,b)=>DateTime.fromISO(a.date_from)-DateTime.fromISO(b.date_from)) : [];
  let exposAvenir= data ? data.expositions.map((o)=>({...o,typeExpo:'aVenir'})).filter((o)=>DateTime.fromISO(o.date_from)>DateTime.now().startOf('day')).sort((a,b)=>DateTime.fromISO(a.date_from)-DateTime.fromISO(b.date_from)) : [];
  useEffect(()=>{
    document.title=t('expositions')
  },[t]);
  return (<div className="expositions">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {exposEnCours.map((expo,i)=><ExpoEnCours key={expo.id} expo={expo}/>)}
        {exposAvenir.map((expo,i)=><ExpoAvenir key={expo.id} expo={expo} size='big'/>)}
      </Grid>
    </Grid>
    <Mod url={'expositions'} refresh={refetch}/>
  </div>);
}
export default Expos;
