import React, {useContext} from "react";
import './Partenaires.scss'
import { SettingsContext } from 'Providers/SettingsProvider';

function Partenaires({item}) {
  const { t } = useContext(SettingsContext);
  const { partners }= item || {};
  return partners && partners.length>0 ? <div className="ressources">
    <div className="ressources-titre">{t('avec')}</div>
    <div className="ressources-nom">{t('partenaires')}</div>
    <div className="ressources-documents">
      {partners && partners.map((doc,i)=>{
        const partner=doc.partner_id;
        return partner ? <React.Fragment key={partner.id}>{i>0 && ', '}<span className="partenaire">
            {partner.url ? <a href={partner.url} target="_blank" rel="noreferrer">{partner.name}</a> : partner.name}
          </span></React.Fragment> : ''}
      )}
      </div>
    </div> : '';
}
export default Partenaires;
