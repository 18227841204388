import React from "react";
import Utils from 'utils/Utils';
import './Exposition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ExpoEnCours({expo,home=false}) {
  const dataExpo=[
    {key:'expo-en-cours',value:expo.title},
    Utils.renderArtistes(expo,false,false),
    {key:null,value:Utils.dates(expo)},
  ];
  if (expo.animation)  dataExpo.push({animation:expo.animation});
  else if (expo.image)  dataExpo.push({image:expo.image,nocrop:expo.nocrop,visuel_background:expo.visuel_background});
  const dataExpoHome=[
    {key:'expo-en-cours',value:Utils.renderArtistes(expo).value},
    {key:'date',value:Utils.dates(expo)},
    (expo.animation ? {animation:expo.animation} : {image:expo.image,credits:false,nocrop:expo.nocrop,visuel_background:expo.visuel_background}),
    {key:'titre',value:expo.title},
  ];
  return <SlugLink item={expo} tile={true}><div className="expo-bloc">
    <CartoucheInfos data={home ? dataExpoHome : dataExpo}/>
  </div></SlugLink>;
}
export default ExpoEnCours;
